@import "Theme.css";

* { box-sizing: border-box; padding: 0; margin: 0 }

html, body, #root { height: 100% }
/*html.h-100, html.h-100 body, html.h-100 #root { height: 100% }*/
body, input { font: 400 1rem/1.5rem Ubuntu, Helvetica, Arial, sans-serif }
body { padding: 0; margin: 0; color: var(--white-100); background-color: var(--bg-100) }

a { text-decoration: none; color: var(--white-100) }
em, i { font-style: normal }
button { font: inherit }
ol, li, ul { margin: 0; padding: 0 }
/*img { vertical-align: middle }*/
hr { height: 0; border: 0; border-top: 1px solid var(--bg-50); width: 100% }
[role=button] { cursor: pointer }

.d-none { display: none }
.d-block { display: block }
.marked, h1 em { color: var(--primary-110) }
.gold { color: var(--secondary-yellow) }
.l { color: var(--white-100) }
.notice { color: var(--white-80) }
.notice.xs { font-size: 0.625rem; line-height: 0.75rem }
.notice.sm { font-size: 0.75rem; line-height: 1rem }
.notice.md { font-size: 0.875rem; line-height: 1.25rem }
.notice.bold-light b { color: #fff }
.note, .note .link { color: var(--white-50) }
.note { font-size: 0.625rem; font-weight: 700; line-height: 0.75rem }
.note.md { font-size: 0.75rem; line-height: 1rem }
.note.lg { font-size: 0.875rem; line-height: 1rem; font-weight: 400 }
.note.xl { font-size: 1rem; line-height: 1.5rem; font-weight: 400 }
.note .link { text-decoration: underline }
.centered { text-align: center; margin: auto 0 }
.clear { clear: both }
.nowrap { white-space: nowrap }
.text-center { text-align: center }
.text-start { text-align: left }
.h-100 { height: 100% }
.w-100 { width: 100% }
.w-auto { width: auto !important }
.mw-100 { max-width: 100%; height: auto; display: block }
.d-inline-block { display: inline-block }
.link { color: var(--primary-110) }
.mt-1 { margin-top: 0.5rem }
.mb-1 { margin-bottom: 0.5rem }
.mb-2 { margin-bottom: 0.75rem }
.mb-4 { margin-bottom: 1.25rem }
.ml-2 { margin-left: 0.5rem }
.me-2 { margin-right: 0.5rem }
.me-3 { margin-right: 0.75rem }
.px-2 { padding: 0 1rem }
.pt-0 { padding-top: 0 !important }
.mx-auto { margin-left: auto; margin-right: auto }
.text-up { text-transform: uppercase }
.small, small { font-size: 0.875rem; line-height: 1rem }
.small-sm { font-size: 0.75rem; line-height: 0.875rem }
.success-text { color: var(--secondary-green-light) }
.rounded-2 { border-radius: var(--radius) }
.aspect-1 { aspect-ratio: 7 / 4 }
.aspect-2 { aspect-ratio: 139 / 120; height: auto }
.pre-line { white-space: pre-line }
.fw-400 { font-weight: 400 }
.fw-bold { font-weight: bold }

.form-control {
  display: block; width: 100%; height: 3rem; padding: 0.75rem 1.25rem; outline: none; color: var(--white-100);
  background-color: var(--bg-80); border-radius: var(--radius); border: 1px solid transparent;
}

.form-control::placeholder { color: var(--white-50) }
.form-control::-webkit-input-placeholder { color: var(--white-50) }
.form-control::-moz-placeholder { color: var(--white-50) }
.form-control:-ms-input-placeholder { color: var(--white-50) }
.form-control:-moz-placeholder { color: var(--white-50) }

.custom-input label { cursor: pointer; display: flex; align-items: center; text-align: left; gap: 0.75rem }
.custom-input input { -webkit-appearance: none; appearance: none; min-width: 1.25rem; min-height: 1.25rem; width: 1.25rem; height: 1.25rem; outline: none; cursor: pointer }
.custom-input input.md { min-width: 1.5rem; min-height: 1.5rem; width: 1.5rem; height: 1.5rem }
.custom-input input[type="checkbox"] { border-radius: 0.375rem; background-color: var(--bg-50) }
.custom-input input[type="radio"] { border-radius: 50%; border: 1px solid var(--bg-30) }
.custom-input .checked { display: flex; justify-content: center; align-items: center }

.custom-input input[type="checkbox"].checked::before {
  content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='%2395B1FF'><path id='vector' fill-rule='evenodd' clip-rule='evenodd' d='M11.7665 0.398931C12.0778 0.678716 12.0778 1.13414 11.7665 1.41392L4.67002 7.79193C4.36135 8.06936 3.86286 8.06936 3.55418 7.79193L0.23348 4.80746C-0.0778262 4.52766 -0.077827 4.07224 0.23348 3.79246L0.445837 3.6016C0.754505 3.32418 1.253 3.32418 1.56167 3.6016L3.55418 5.39239C3.86286 5.66978 4.36135 5.66978 4.67002 5.39239L10.4383 0.208068C10.7469 -0.069356 11.2454 -0.069356 11.5542 0.208068L11.7665 0.398931Z'/></svg>");
  font-size: 0.75rem; color: var(--primary-110);
}
.custom-input input[type="radio"].checked { border-color: var(--primary-100); border-width: 2px }
.custom-input input[type="radio"].checked::before { content: ''; border-radius: 50%; width: 0.625rem; height: 0.625rem; background-color: var(--primary-100) }

.custom-input input:disabled { border-color: #404551; background-color: #404551; cursor: default }
.custom-input input:disabled + span { color: #404551 }

.custom-input input[type="checkbox"].success:disabled { border-color: var(--secondary-green-light); background-color: var(--primary-110); border-radius: var(--radius); width: 1.5rem; height: 1.5rem }
.custom-input input[type="checkbox"].checked.success::before { color: var(--bg-100); content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='%2301030B'><path id='vector' fill-rule='evenodd' clip-rule='evenodd' d='M11.7665 0.398931C12.0778 0.678716 12.0778 1.13414 11.7665 1.41392L4.67002 7.79193C4.36135 8.06936 3.86286 8.06936 3.55418 7.79193L0.23348 4.80746C-0.0778262 4.52766 -0.077827 4.07224 0.23348 3.79246L0.445837 3.6016C0.754505 3.32418 1.253 3.32418 1.56167 3.6016L3.55418 5.39239C3.86286 5.66978 4.36135 5.66978 4.67002 5.39239L10.4383 0.208068C10.7469 -0.069356 11.2454 -0.069356 11.5542 0.208068L11.7665 0.398931Z'/></svg>") }

.btn { display: flex; padding: 0.75rem 1.5rem; justify-content: center; align-items: center; align-self: stretch; border-radius: var(--radius); font-weight: 700; text-align: center; border: 0; cursor: pointer; width: 100%; background-color: transparent; color: var(--white-50) }
.btn:disabled, .btn.disabled { opacity: 0.65; cursor: default }
.btn.btn-primary:disabled, .btn.btn-primary.disabled { opacity: 1; background-color: var(--bg-50); color: var(--white-50) }
.btn-primary { background-color: var(--primary-100); color: var(--white-100) }
.btn-secondary { background-color: var(--bg-50); color: var(--white-80) }
.btn-gold { background-color: var(--secondary-yellow); color: var(--bg-100) }
.btn-warning { background-color: var(--warning-100); color: var(--white-100) }
.btn.sm { padding: 0.5rem 1rem; width: auto; font-size: 0.875rem; line-height: 1.25rem }
.btn.md { padding: 0.75rem 1.25rem; width: auto; font-size: 0.875rem; line-height: 1rem }

.btn-choice { background-color: var(--bg-80); border: 1px solid var(--bg-80); color: var(--white-50); padding: 1rem 1.25rem; gap: 0.5rem; font-size: 0.875rem }
.btn-choice i { font-size: 1.25rem }
.btn-choice i + em { flex: 1 0 0; text-align: left }
.btn-choice.active { border-color: var(--primary-100); color: var(--white-100) }

.animate-choice { animation-duration: .15s; will-change: transform,opacity; animation-name: animate-choice; animation-timing-function: ease-in-out; transition: background-color .3s,box-shadow .3s }
@keyframes animate-choice {
  0%   { opacity: 0; transform: translateY(40px) }
  50%   { opacity: 0.5; transform: translateY(20px) }
  100% { opacity: 1; transform: translateY(0) }
}

.btn-link { border: 0; padding: 0; background-color: transparent; cursor: pointer }
.back { display: flex; align-items: center; gap: 0.5rem; color: var(--white-50); align-self: auto; width: auto }
.close { float: right; margin-bottom: 1rem; padding: 0.25rem }

.tag { padding: 0.5rem 0.75rem; border-radius: 2.75rem; background: var(--bg-80); color: var(--white-80); font-size: 0.75rem; font-weight: 700; line-height: 0.75rem; white-space: nowrap }
.tag-form { border-radius: var(--radius); border: 1px solid var(--bg-50); cursor: default }
.tag-btn { padding: 0.5rem 1rem; font-size: 0.875rem; font-weight: 700; line-height: 1.25rem; color: var(--white-50) }
.tag-btn-sm { padding: 6px 0.75rem; background-color: rgba(149, 177, 255, 0.2); font-weight: 400; color: #fff }
.tag-input { padding: 0.5rem 0.75rem; font-size: 0.75rem; line-height: 1rem; color: var(--white-80) }
.tag-note { padding: 4px 8px; border-radius: 20px; background-color: #3A4051 }
.tag-gold { color: var(--secondary-yellow); background-color: var(--bg-100); font-size: 1rem; line-height: 1.5; border-radius: var(--radius); padding: 0.5rem 1rem }

h1, .h1 { font-size: 1.75rem; line-height: 2rem }
h2, .h2 { font-size: 1.5rem; line-height: 1.75rem }
h3, .h3 { font-size: 1.25rem; line-height: 1.5rem }
h4, .h4 { font-size: 1rem; line-height: 1.5rem }

ul { margin-left: 12px }
li { margin-bottom: 0.75rem }
/*li::marker { vertical-align: middle }*/
li:last-child { margin-bottom: 0 }

.list { list-style: none; counter-reset: my-awesome-counter; width: calc(100% - 30px); margin-left: 30px }
.list > li { counter-increment: my-awesome-counter; position: relative }
.list li::before {
  content: counter(my-awesome-counter); color: var(--bg-100); font-weight: bold; font-size: 0.875rem; background-color: var(--secondary-green-light); border-radius: var(--radius-sm);
  --size: 1.25rem; position: absolute; left: calc(-1 * var(--size) - 10px); line-height: var(--size); width: var(--size); height: var(--size); top: 3px; text-align: center;
}

.phone { background-color: var(--bg-80); border-radius: 40px 40px 0 0; padding: 1rem; margin: 0 1.25rem; mask-image: linear-gradient(#000 64%, transparent); aspect-ratio: 7 / 9; overflow: hidden }
.phone img { max-width: 100%; display: block; height: auto }

.progress, .progress div { border-radius: var(--radius-sm); height: 0.5rem; width: 100% }
.progress:not(.common) { background-color: var(--bg-50) }
.progress div { background-color: var(--primary-100) }
.progress.common, .progress.common div { height: 0.25rem }
.progress.common div { background-color: var(--secondary-mint) }

.progress.animate, .progress.animate div { transition: width 0.4s linear 0s }

.progress.sm, .progress.sm div { height: 0.125rem }
.progress.md, .progress.md div { height: 0.25rem }

.progress.lg, .progress.lg div { position: relative; min-height: 2rem; border-radius: var(--radius) }
.progress.lg { overflow: hidden }
.progress.lg div { border-top-right-radius: 0; border-bottom-right-radius: 0 }
.progress.lg .value { position: absolute; z-index: 1; left: 50%; top: 50%; transform: translate(-50%, -50%); font-weight: 700; font-size: 0.875rem }

.step-circle { min-width: 0.5rem; height: 0.5rem; border-radius: 50%; border: 2px solid var(--bg-50) }
.step-circle.active, .step-circle.sub-active { border-color: var(--primary-110) }
.step-circle.active { background-color: var(--primary-110) }

.progress-wrap { width: 100%; font-weight: 700; display: flex; flex-direction: column; align-items: flex-start; gap: 0.75rem; align-self: stretch }
.progress-wrap > div { width: 100% }
.progress-wrap div:first-child { display: flex; align-items: center; justify-content: space-between }
.progress-wrap.disabled { color: var(--white-50) }
.progress-wrap em { color: var(--white-50); font-weight: normal }
.progress-wrap .line { width: 0; height: 1.25rem; border-left: 1px dashed var(--white-50); align-self: center }

.panel { padding: 0.5rem 0.75rem; border-radius: var(--radius-sm); background-color: var(--bg-70) }

.panel-comment { padding: 0.75rem 1rem; border-radius: var(--radius); background-color: var(--bg-80); width: 100%; text-align: left; color: var(--white-80) }
.panel-comment ul, .panel-comment ol { margin-left: 1.25rem; margin-top: 1rem }
/*.panel-comment .slides { margin-bottom: 1.25rem }*/
.comment { display: flex; flex-direction: column; align-items: center; gap: 1.25rem; align-self: stretch; text-align: center }
.comment .text { color: var(--white-80); font-size: 0.875rem; line-height: 1.25rem; height: 3.75rem; display: flex; flex-direction: column; justify-content: center; align-self: stretch }
.stars { display: flex; justify-content: center; align-items: center; gap: 0.25rem; align-self: stretch }
.paragraph { display: flex; flex-direction: column; align-items: center; gap: 0.75rem; align-self: stretch }

.panel-timer { position: fixed; z-index: 1; top: 0; left: 0; width: 100%; background-color: rgba(243,170,81,.8); padding: 1rem 0; font-size: 0.875rem; line-height: 1rem }
.panel-timer.text-center { position: absolute; right: 0; top: 0; border-radius: var(--radius) }
.panel-timer + .wrap > .top { margin-top: 48px }

.wrap { display: flex; flex-direction: column; justify-content: space-between; max-width: var(--max-width); margin: 0 auto }
.root { display: flex; height: 100%; flex-direction: column; justify-content: space-between }
.top { display: flex; padding: 1.25rem 1rem; flex-direction: column; align-items: flex-start; gap: 1.25rem }

.top.type-center, .wrap.fixed .top { align-items: center; text-align: center; height: 100% }

.top.type-boolean .image { width: 160px }

.bottom { display: flex; flex-direction: column; align-items: center; gap: 11px; width: 100%; padding: 1.25rem 1rem 10px; background-color: var(--bg-100) }
footer, footer a { color: var(--white-50) }
footer { text-align: center; font-size: 0.875rem; width: 100%; line-height: 1rem }
footer .circle { display: inline-block; width: 4px; height: 4px; border-radius: 50%; margin: 0 12px; background-color: var(--white-50); vertical-align: middle }

.wrap.fixed { height: 100% }
.top-up + .wrap.fixed { height: calc(100% - 24px) }
.wrap.fixed > .top { padding-bottom: 0 }
.wrap.fixed > .top:after { content: ''; min-height: 60px; width: 100% }
.wrap.fixed.md > .top:after { min-height: 101px }
.wrap.fixed.lg > .top:after { min-height: 169px }
.wrap.fixed > .bottom { position: fixed; z-index: 10; bottom: 0; max-width: var(--max-width) }

.choice-list { display: flex; flex-direction: column; align-items: flex-start; gap: 0.75rem; align-self: stretch; margin-top: auto }

.choice-list.type-start-line .btn { justify-content: start }

.choice-list.type-inline { flex-wrap: wrap; align-self: start; flex-direction: row }
.choice-list.type-inline .btn-choice { background-color: var(--bg-70); width: auto; align-self: start; padding: 0.5rem 0.75rem 0.5rem 0.5rem }
.choice-list.type-inline .btn-choice .checkbox { order: -1 }
.choice-list.type-inline:not(.multiple) .btn-choice { font-size: 1rem; line-height: 1.25rem; font-weight: 700 }
.choice-list.type-inline.multiple .btn-choice { border: 0; color: var(--white-80); font-weight: 400; font-size: 0.75rem; line-height: 1rem }

.choice-list.type-grid { display: grid; grid-template-columns: repeat(3, 1fr) }
.choice-list.type-grid .btn-choice { padding: 1.25rem 0; font-size: 0.75rem; background-color: var(--bg-70); line-height: 1.5 }

.choice-list.type-boolean { flex-direction: row }
.choice-list.type-boolean .btn-choice { flex-direction: column; padding: 2rem 1.25rem; gap: 1.25rem; line-height: 1.25rem }
.choice-list.type-boolean i svg { display: block; height: 24px }

.choice-list.compact .btn-choice { padding: 1rem }
.choice-list.compact .btn-choice:not(.active) { border-color: var(--bg-50) }
.choice-list.compact .btn-choice.active { background-color: rgba(230, 233, 245, 0.03) }

.progress-wrap .choice-list.type-boolean .btn-choice { padding: 0.5rem 1.25rem; background-color: var(--bg-60) }
.progress-wrap .choice-list.type-boolean .btn-choice em { color: var(--white-100) }

.choice-list.type-line { display: grid; grid-template-columns: repeat(3, 1fr); grid-column-gap: 0.75rem; grid-row-gap: 1.25rem; grid-auto-rows: minmax(min-content, max-content) }
.choice-list.type-line .btn { padding: 2.5rem 1rem 1.25rem 1rem; flex-direction: column; line-height: 1.25rem; gap: 1.25rem }
.choice-list.type-line .btn i { font-size: 1.75rem; line-height: 2rem }
.choice-list.type-line .btn img { border-radius: 50%; display: block }
.choice-list.type-line .btn .thumb { position: relative; padding: 0.25rem }

.choice-list.type-pic .btn { background-color: transparent; gap: 0.25rem; padding: 0; border: 0; color: var(--white-100); font-weight: 400; align-self: start }
.choice-list.type-pic .btn .checkbox { position: absolute; top: 0; right: 0; display: none }
.choice-list.type-pic .btn .checkbox input[type="checkbox"] { width: 1.75rem; height: 1.75rem; background-color: var(--primary-100); border-radius: 50% }
.choice-list.type-pic .btn input.checked::before { color: var(--white-100); content: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='%23eeeeee'><path id='vector' fill-rule='evenodd' clip-rule='evenodd' d='M11.7665 0.398931C12.0778 0.678716 12.0778 1.13414 11.7665 1.41392L4.67002 7.79193C4.36135 8.06936 3.86286 8.06936 3.55418 7.79193L0.23348 4.80746C-0.0778262 4.52766 -0.077827 4.07224 0.23348 3.79246L0.445837 3.6016C0.754505 3.32418 1.253 3.32418 1.56167 3.6016L3.55418 5.39239C3.86286 5.66978 4.36135 5.66978 4.67002 5.39239L10.4383 0.208068C10.7469 -0.069356 11.2454 -0.069356 11.5542 0.208068L11.7665 0.398931Z'/></svg>") }
.choice-list.type-pic .btn.active .checkbox { display: block }

.items { display: inline-flex; flex-direction: column; align-items: flex-start; gap: 1.25rem }
.items.md { gap: 0.75rem }
.item { width: 100%; display: flex; align-items: center; gap: 0.75rem }
/*.item > img { margin: 0 0.75rem }*/
.item p:first-child { margin-bottom: 0.25rem }

.cards { display: grid; grid-template-columns: repeat(2, 1fr); grid-column-gap: 1.25rem; grid-row-gap: 1.25rem }
.card { display: flex; padding: 0.5rem; flex-direction: column; align-items: flex-start; border-radius: var(--radius-md); background-color: var(--bg-90) }
.card-bg, .load-image { background-repeat: no-repeat; background-position: center; background-size: cover; justify-content: flex-end }

.scrollable { overflow: auto; scrollbar-width: none; -ms-overflow-style: none }
.scrollable::-webkit-scrollbar { display: none }

/*.achives { width: 100%; overflow: hidden; overflow-x: auto; scrollbar-width: none; -ms-overflow-style: none; !*margin: 2.5rem 0;*! }
.achives::-webkit-scrollbar { display:none }
.achives .items { flex-direction: row }
.achives .item { flex-direction: column }*/

.block { border-radius: var(--radius); background-color: var(--bg-90); padding: 1.25rem; flex-direction: column; align-items: center; width: 100% }
.block.sm { padding: 1rem }
.block label { color: var(--white-50) }
.block-drop { border-radius: var(--radius); background-color: var(--bg-70); flex-direction: column; align-items: start; padding: 0.75rem 1rem; cursor: pointer }
.block-drop .notice { display: none }
.block-drop.active .notice { display: block }

.block-plan { width: 100% }
.block-plan, .block-plan .item { border-radius: var(--radius) }
.block-plan { border: 1px solid var(--bg-30); cursor: pointer; padding: 0.75rem 1rem }
.block-plan .item { font-weight: 700 }
.block-plan .item .nowrap { color: var(--white-50); display: flex; align-items: flex-start; gap: 0.25rem; font-weight: 700 }

.block-plan .promo { position: absolute; left: 0; width: 100%; top: -10px; text-align: center }
.block-plan .promo em { background-color: var(--bg-50); color: var(--white-100); border-radius: var(--radius-sm); display: inline-block; padding: 0.125rem 0.5rem }

.block.items { padding: 1.25rem 0.75rem }

.block .block { background-color: var(--bg-70); align-items: start }
.block .block .panel { background-color: var(--bg-60) }
.block .block .stars { justify-content: start }

.nowrap big { font-size: 3.25rem; line-height: 2rem; font-weight: 400 }
.nowrap div { display: flex; flex-direction: column; align-items: center }
.nowrap em { font-size: 0.875rem; line-height: 1rem }
.nowrap small { font-size: 0.5rem; line-height: 0.625rem }

/*.block-plan.active { background-color: var(--primary-100) }*/
.block-plan.active { background-color: var(--bg-60); border-color: var(--primary-100); cursor: default }
.block-plan.active .item .nowrap { color: var(--white-100) }

.block-plan.active .promo em { background-color: var(--primary-100) }

.paypal-buttons-layout-horizontal { display: block !important }

.row { width: 100%; display: flex; align-items: center; gap: 1rem; flex-direction: row }
.row.stretch { align-items: stretch }
.row.wide { justify-content: space-between }
.row.center { justify-content: center }
.row.md { gap: 1.25rem }
.row.sm { gap: 0.75rem }
.row.xs { gap: 0.25rem }
.right { margin-left: auto }
.ava { border-radius: 50% }

.gap-0 { gap: 0 }
.gap-2 { gap: 0.5rem }
.gap-3 { gap: 0.75rem }

.goal { text-align: center; width: 100% }
.goal .subtitle { background-color: var(--secondary-yellow); color: var(--bg-100); font-weight: 700; padding: 0.25rem 1.25rem; border-top-left-radius: var(--radius); border-top-right-radius: var(--radius) }
.goal .subtitle.compact { line-height: 1rem }
.goal .sad, .goal .happy { display: flex; padding: 0.75rem; flex-direction: column; align-items: center; gap: 0.75rem; flex: 1 0 0 }
.goal .sad { background-color: var(--bg-60) }
.goal .happy { background-color: #2B3242; color: var(--secondary-yellow) }
.goal .info {
  display: flex; padding: 0.75rem; flex-direction: column; justify-content: center; align-items: center; gap: 0.75rem; align-self: stretch;
  background-color: var(--bg-70); border-bottom-left-radius: var(--radius); border-bottom-right-radius: var(--radius);
}
.goal .info .row { gap: 0.5rem }
.goal .info .row p { background-color: var(--bg-50); padding: 0.5rem 0.75rem; border-radius: var(--radius-sm); width: 50% }

.block-plan.new-timer { border: 0; background-color: #ADDC4B; font-size: 1rem; color: var(--bg-100); padding: 20px }
.block-plan.new-timer strong { font-size: 14px; line-height: 1.1; text-align: center }
.block-plan.new-timer .promo { position: relative; top: -29px; margin-bottom: -21px }
.block-plan.new-timer .promo em { background-color: #0050FF; white-space: nowrap }
.block-plan.new-timer .promo small { font-size: 12px; position: relative }
.block-plan.new-timer .promo small:before { content: ''; position: absolute; left: 0; top: 50%; margin-top: -1px; width: 100%; border-top: 2px solid #E26564 }
.block-plan.new-timer .items { align-items: center }
.clock { justify-content: center }
.clock b { font-size: 28px }
.clock .note { font-size: 8px }
.facing { background-color: var(--white-100); border-radius: var(--radius); padding: 14px 8px; color: var(--bg-100) }

.paypal-buttons { width: 100% }

.rive { width: 100%; flex-shrink: 0; position: relative }
.rive canvas { position: absolute; left: 0; top: 0; width: 100%; height: 100% }

.load:before {
  content: ''; position: absolute; left: 50%; top: 50%; margin-left: -0.5rem; margin-top: -0.5rem; width: 1rem; height: 1rem;
  border: .15em solid currentColor; border-right-color: transparent; border-radius: 50%;
  animation: spinner-border .75s linear infinite
}
/*.load canvas { position: absolute; left: 0; top: 0; width: 100%; height: 100% }*/

@keyframes spinner-border {
  to { transform: rotate(1turn) }
}

body.pop { overflow: hidden }
.shadow { position: fixed; z-index: 9; left: 0; top: 0; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5) }
.window { position: fixed; z-index: 10; background-color: var(--bg-80); padding: 1.25rem; border-radius: var(--radius); left: 50%; top: 50%; transform: translate(-50%, -50%); width: 100%; max-width: 350px }

#payment-message { color: #CC1818; font-size: 1rem; line-height: 1.25rem; padding-top: 12px; text-align: center }

.spinner, .spinner:before, .spinner:after { border-radius: 50% }
.spinner { color: #fff; font-size: 22px; text-indent: -99999px; position: relative; width: 20px; height: 20px; box-shadow: inset 0 0 0 2px; transform: translateZ(0) }
.spinner:before, .spinner:after { position: absolute; content: ''; width: 10.4px }
.spinner:before {
  height: 20.4px; background: #fff; border-radius: 20.4px 0 0 20.4px; top: -0.2px; left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px; transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s; animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
  height: 10.2px; background: rgba(0, 0, 0, 0.3); border-radius: 0 10.2px 10.2px 0; top: -0.1px; left: 10.2px;
  -webkit-transform-origin: 0 10.2px; transform-origin: 0 10.2px;
  -webkit-animation: loading 2s infinite ease; animation: loading 2s infinite ease;
}

.spinner-fixed { position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 100; background-color: var(--bg-100); display: flex; align-items: center; justify-content: center }
/*.spinner-fixed .spinner { width: 60px; height: 60px }
.spinner-fixed .spinner:before { width: 30.4px; height: 60.4px; border-radius: 60.4px 0 0 60.4px; top: -0.2px; left: -0.2px; -webkit-transform-origin: 30.4px 30.2px; transform-origin: 30.4px 30.2px }
.spinner-fixed .spinner:after { width: 30.4px; height: 30.2px; border-radius: 0 30.2px 30.2px 0; top: -0.1px; left: 30.2px; -webkit-transform-origin: 0 30.2px; transform-origin: 0 30.2px }*/

@keyframes loading {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg) }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg) }
}

.fade-in { animation: fade-in 1s }

@keyframes fade-in {
  from { opacity: 0 }
  to { opacity: 1 }
}

.fade-in-translate { animation: fade-in-translate 1s; animation-timing-function: ease-in-out; will-change: transform,opacity }

@keyframes fade-in-translate {
  from { opacity: 0; transform: translate(20px) }
  to { opacity: 1; transform: translate(0) }
}

.dots { white-space: break-spaces }
.dots span { animation-name: blink; animation-duration: 1.4s; animation-iteration-count: infinite; animation-fill-mode: both; margin-left: 2px; display: inline-block; font-size: 1.1em }
.dots span:nth-child(2) { animation-delay: .2s }
.dots span:nth-child(3) { animation-delay: .4s }

@keyframes blink {
  0% { opacity: .2 }
  20% { opacity: 1 }
  to { opacity: .2 }
}

.w-50 { width: 50% }

/*
body.light {
  --primary-110: #4D5EBF;
  --bg-50: #D0D5E5;
  --bg-100: #fff;
  --white-80: #161B26;
  --white-100: #01030B;
}
body.light .btn-secondary { background-color: #eee }
body.light .btn-primary { color: #eee }*/
