:root {
  --white-50: #707993;
  --white-80: #B3B9CD;
  --white-100: #eee;

  --primary-100: #6589EC;
  --primary-110: #95B1FF;
  --onboarding-primary-light: #6589EC;
  --secondary-green-light: #5DDEA7;
  --secondary-yellow: #FFD15A;
  --secondary-mint: #65E9FF;
  --warning-100: rgba(236, 125, 101, 1);

  --bg-30: #4E5466;
  --bg-50: #2B3242;
  --bg-60: #242A37;
  --bg-70: #1B202C;
  --bg-80: #161B26;
  --bg-90: #121620;
  --bg-100: #01030B;

  --radius: 0.5rem;
  --radius-sm: 0.25rem;
  --radius-md: 0.75rem;

  --max-width: 444px;
}
